<template>
  <div>
    <div class="property">
      <div class="label">
        {{ $t('process_editor.element_properties.user_task.multitask') }}
        <el-checkbox :value="isMultiTaskValue" @change="changeMultiTask"></el-checkbox>
      </div>
    </div>
    <template v-if="isMultiTaskValue">
      <div class="property">
        <div class="label">
          {{ $t('process_editor.element_properties.user_task.create_type') }}
        </div>
        <el-select
            size="mini"
            v-model="multiTaskCreateType"
            :placeholder="$t('process_editor.element_properties.user_task.create_type')"
            @input="changeMultiTaskType"
        >
          <el-option
              v-for="item in Object.keys($t('process_editor.element_properties.user_task.create_types'))"
              :key="item"
              :label="$t(`process_editor.element_properties.user_task.create_types.${item}`)"
              :value="item">
          </el-option>
        </el-select>
      </div>
      <div class="property">
        <div class="label">
          {{ $t('process_editor.element_properties.user_task.collection') }}
          <el-input
              size="mini"
              :value="extensions.collection_name"
              @input="changeMultiTaskCollection($event)"
          ></el-input>
        </div>
      </div>
      <div class="property">
        <div class="label">
          {{ $t('process_editor.element_properties.user_task.collection_item') }}
          <el-input
              size="mini"
              :value="extensions.collection_item_name || 'item'"
              @input="changeMultiTaskCollectionItem($event)"
          ></el-input>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import mixins from '../mixins'

import MultiTaskUtils from '@/services/BPMNEditor/infrastructure/service/MultiTaskUtils'

export default {
  name: 'SubProcessEditor',
  inject: ['getModeler'],
  mixins: [mixins],
  data () {
    return {
      isMultiTaskValue: MultiTaskUtils.isMultiTask(this.element),
      multiTaskCreateType: MultiTaskUtils.getMultiTaskCreateType(this.element)
    }
  },
  methods: {
    changeMultiTaskCollection (collection) {
      this.$emit('change-extension', { name: `collection_name`, value: collection })
      this.$nextTick(() => {
        this.changeMultiTask(this.isMultiTaskValue, MultiTaskUtils.getMultiTaskCreateType(this.element))
      })
    },
    changeMultiTaskCollectionItem (item) {
      this.$emit('change-extension', { name: `collection_item`, value: item })
      this.$nextTick(() => {
        this.changeMultiTask(this.isMultiTaskValue, MultiTaskUtils.getMultiTaskCreateType(this.element))
      })
    },
    changeMultiTaskType (value) {
      this.changeMultiTask(true, value)
    },
    changeMultiTask (value, type = 'parallel') {
      const collectionName = this.extensions['collection_name']
      const collectionItem = this.extensions['collection_item'] || 'item'
      const element = MultiTaskUtils.buildLoopCharacteristics(this.getModeler(), value, type, collectionName, collectionItem)
      this.$emit('change-attribute', {
        name: 'loopCharacteristics',
        value: element?.businessObject || null
      })

      this.isMultiTaskValue = value
    }
  }
}
</script>
